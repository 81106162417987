'use client';
import React, { useCallback, useEffect } from 'react';

import { doNothing } from '@bloom/ui/utils/empty-value';

import { IErrorAsyncAction } from '@bloom/library/components/hooks/useFetch';
import { useSafeState } from '@bloom/library/components/hooks/useSafeState';
import { FlashMessageTypes } from '@bloom/library/types/flashMessage';

import { getErrorMessageFromResponse } from './actions';

import { FlashMessage } from '.';

interface IFlashMessageState {
  message: string;
  variant: FlashMessageTypes;
}

type TFlashMessageContext = IFlashMessageState &
  Record<
    'showErrorMessage' | 'showInfoMessage' | 'showWarningMessage' | 'showSuccessMessage',
    (message: string) => void
  > & {
    hideMessage: () => void;
    showErrorMessageFromResponse: (res: IErrorAsyncAction) => void;
  };

const initState: IFlashMessageState = {
  message: '',
  variant: FlashMessageTypes.ERROR,
};

export const FlashMessageContext = React.createContext<TFlashMessageContext>({
  ...initState,
  hideMessage: doNothing,
  showErrorMessage: doNothing,
  showErrorMessageFromResponse: doNothing,
  showInfoMessage: doNothing,
  showSuccessMessage: doNothing,
  showWarningMessage: doNothing,
});

let timer: ReturnType<typeof setTimeout>;

const InfoMessageProvider: React.FC<{ children?: React.ReactNode; withMessage?: boolean }> = ({
  children,
  withMessage = true,
}) => {
  const [state, setState] = useSafeState<IFlashMessageState>(initState);

  const hideMessage = useCallback(() => {
    setState({ message: '', variant: state.variant });
  }, [setState, state.variant]);

  const showErrorMessage = useCallback(
    (message: string) => {
      setState({ message, variant: FlashMessageTypes.ERROR });
    },
    [setState]
  );

  const showErrorMessageFromResponse = useCallback(
    (res: IErrorAsyncAction) => {
      showErrorMessage(getErrorMessageFromResponse(res));
    },
    [showErrorMessage]
  );

  const showInfoMessage = useCallback(
    (message: string) => {
      setState({ message, variant: FlashMessageTypes.INFO });
    },
    [setState]
  );

  const showSuccessMessage = useCallback(
    (message: string) => {
      setState({ message, variant: FlashMessageTypes.SUCCESS });
    },
    [setState]
  );
  const showWarningMessage = useCallback(
    (message: string) => {
      setState({ message, variant: FlashMessageTypes.WARNING });
    },
    [setState]
  );

  const value = React.useMemo(
    () => ({
      hideMessage,
      message: state.message,
      showErrorMessage,
      showErrorMessageFromResponse,
      showInfoMessage,
      showSuccessMessage,
      showWarningMessage,
      variant: state.variant,
    }),
    [
      hideMessage,
      showErrorMessage,
      showErrorMessageFromResponse,
      showInfoMessage,
      showSuccessMessage,
      showWarningMessage,
      state.message,
      state.variant,
    ]
  );

  useEffect(() => {
    if (state.message) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        hideMessage();
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [setState, hideMessage, state.message]);

  return (
    <FlashMessageContext.Provider value={value}>
      {withMessage ? <FlashMessage /> : null}
      {children}
    </FlashMessageContext.Provider>
  );
};

function useInfoMessage(): TFlashMessageContext {
  const context = React.useContext(FlashMessageContext);

  if (context === undefined) {
    throw new Error('useInfoMessage must be used within a InfoMessageProvider');
  }

  return context;
}

export { InfoMessageProvider, useInfoMessage };

export enum FlashMessageTypes {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  UNSET = '',
}

export interface IFlashMessageState {
  message: string;
  type: FlashMessageTypes;
}

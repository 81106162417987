__turbopack_context__.v({
  "closeButton": "FlashMessageV2-module__ogbl6W__closeButton",
  "container": "FlashMessageV2-module__ogbl6W__container",
  "content": "FlashMessageV2-module__ogbl6W__content",
  "error": "FlashMessageV2-module__ogbl6W__error",
  "info": "FlashMessageV2-module__ogbl6W__info",
  "message": "FlashMessageV2-module__ogbl6W__message",
  "success": "FlashMessageV2-module__ogbl6W__success",
  "warning": "FlashMessageV2-module__ogbl6W__warning",
});

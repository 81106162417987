import currencies from '@bloom/library/assets/data/currencies.json';
import { round } from '@bloom/library/utils/misc';

export function getCurrencySymbol(currencyCode: string) {
  return currencies[currencyCode as keyof typeof currencies]?.symbol || '';
}

export function getCurrencyDecimalPlaces(currencyCode: string) {
  const decimalPlaces = currencies[currencyCode as keyof typeof currencies]?.decimal_digits;
  return decimalPlaces !== undefined ? decimalPlaces : 2;
}

export function formatAmount(amount = 0, currencyCode = '', divider = null, decimalPlaces = 0) {
  // amount - number
  // currencyCode - current currencyCode
  // divider - thousand divider
  // decimalPlaces - length of decimal places

  if (Number.isNaN(parseInt(amount, 10))) {
    return '';
  }

  const cur = getCurrencySymbol(currencyCode);
  // by default we don't show nubmers after absolute part
  const c = Math.abs(decimalPlaces) || 0;
  // comma is default thousand divider
  const d = divider || ',';
  // sign of number
  const s = +amount < 0 ? '-' : '';
  // get abousolute number form amount
  const i = `${parseInt(Math.abs(+amount || 0).toFixed(c), 10)}`;
  // count comma dividers
  const j = i.length > 3 ? i.length % 3 : 0;

  return `${s}${cur}${j ? i.substr(0, j) + d : ''}${i
    .substr(j)
    .replace(/(\d{3})(?=\d)/g, `$1${d}`)}${
    c
      ? `.${round(+amount || 0, c)
          .toFixed(c)
          .slice(-c)}`
      : ''
  }`;
}

export function formatMoney(amount = 0, currencyCode = 'USD') {
  return formatAmount(amount, currencyCode, null, getCurrencyDecimalPlaces(currencyCode));
}

/**
 * Returns formatted input value.
 * Note, it differs from formatAmount in that it allows
 * for incomplete numbers, e.g. '1,000.1', '20.'.
 *
 * @param String number is numeric Input value
 *
 */
export function formatNumberInput(value: string) {
  // Remove any non-digit characters
  const numericValue = value.replace(/[^0-9.]/g, '');

  // Split the value into whole and decimal parts
  const [wholePart, ...decimalParts] = numericValue.split('.');

  // Format the whole part with commas
  // It also should handle an empty string
  const formattedWholePart = String(wholePart && +wholePart).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine the whole and decimal parts with a decimal point
  let formattedValue = formattedWholePart;
  if (decimalParts.length > 0) {
    const decimalPart = decimalParts.join('');
    formattedValue += `.${decimalPart}`;
  }

  // Handle input starting with a decimal point
  if (formattedValue.startsWith('.')) {
    formattedValue = `0${formattedValue}`;
  }

  return formattedValue;
}

/**
 * Capitalize string
 * @param  {string} string
 * @return {string}
 */
export function capitalize(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Camel case a word, e.g. it turns WEEKLY to Weekly.
 * It differs from capitalize() in that
 * all chars after the first one are lower case.
 * @param  {string} word
 * @return {string}
 */
export function camelCase(value = '') {
  if (['object', 'function'].indexOf(typeof value) > -1) {
    console.error('Passed value cannot be converted to string');
    return '';
  }

  const word = String(value);
  return `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;
}

export function snakeToWords(inputString: string, formatter?: (word: string) => string) {
  const words = inputString.split('_');

  return words
    .map((word) => (formatter ? formatter(word) : word))
    .join(' ')
    .trim();
}

export function isHexColor(str: string) {
  return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(str);
}

export function camelCaseToSentence(str: string) {
  return str.split(/(?=[A-Z])/).join(' ');
}

export function formatPhoneNumber(phoneNumberString = '') {
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? `+${match[1]}` : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return phoneNumberString;
}

export function escapeHTML(html: string) {
  if (typeof document === 'undefined') {
    return html;
  }

  if (typeof Option === 'undefined') {
    return html;
  }

  return new Option(html).innerHTML;
}

export function stripTags(str = '') {
  return str.replace(/<\/?[^>]+>/gi, '');
}

export function formatTransactionMethod(paymentMethod) {
  const { displayName, paymentAttributes, paymentType } = paymentMethod || {};
  const { cardType } = paymentAttributes || {};
  if (paymentType === 'CREDIT_CARD') {
    return `${cardType?.toUpperCase()} • • • ${displayName?.replaceAll('*', '')}`;
  }
  return displayName;
}

export function stripNonLatinCharacters(str: string) {
  return str ? str.replace(/[\u0250-\ue007]/g, '') : '';
}

export function wrapUnwrappedLinks(text: string | null | undefined): string {
  if (!text) return '';

  // Regular expression to match URLs
  const urlRegex =
    /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,}\b(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)/gi;

  // Replace URLs with anchor tags
  return text.replace(urlRegex, (url) => {
    // Remove any trailing punctuation
    const cleanUrl = url.replace(/[.,!?]$/, '');
    return `<a href="${cleanUrl}" target="_blank" rel="noopener noreferrer" class="text-inherit underline hover:no-underline">${cleanUrl}</a>`;
  });
}

import React from 'react';

import { AccountBrandInfoResponse } from '@bloom/codegen/models/AccountBrandInfoResponse';
import { AccountResponse } from '@bloom/codegen/models/AccountResponse';
import { PublicAccountResponse } from '@bloom/codegen/models/PublicAccountResponse';

import countryNames from '@bloom/library/assets/data/countryNames.json';

export function getBusinessNameFromAccount(
  account: PublicAccountResponse | AccountBrandInfoResponse | AccountResponse | null | undefined
) {
  if (account) {
    const user = 'owner' in account ? account.owner : account.user;
    if (user) {
      return (
        account.homepageName || `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || ''
      );
    }
  }

  return '';
}

export function getUserNameFromAccount(
  account: PublicAccountResponse | AccountBrandInfoResponse | AccountResponse | null | undefined
) {
  if (account) {
    const user = 'owner' in account ? account.owner : account.user;
    if (user) {
      return `${user.firstName} ${user.lastName}`.trim() || '';
    }
    return '';
  }

  return '';
}

export function getProjectClient(project) {
  if (!project) {
    return {};
  }
  // Not sure why we need project.client at all, but keep it as a fallback.
  // project.clientDetails should go first, because its values can be updated
  // unlike values of project.client (see issue https://app.clickup.com/t/1pau7h).
  return project.clientDetails || project.client || project.primaryContact || {};
}

export function humanFileSize(size) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

export function isEmail(value = '') {
  return /^[^@\s]+@[^\s]+\.[a-zA-Z]+$/.test(value);
}

export function isPhoneNumber(value = '') {
  return /^\([\d]{3}\) [\d]{3}-[\d]{4}$/.test(value);
}

export function isValidAddress(address) {
  return Boolean(
    address &&
      (address.street1 || address.street) && // TODO delete street1 when all addresses will be of type IAddressNew
      address.city &&
      (address.state || address.country)
  );
}

/**
 * If no country is set, consider it to be United States address.
 */
export function isAmericanAddress(address) {
  const { country } = address || {};
  return !country || country === 'US';
}

/**
 * Prevents state and province being set at the same time.
 */
export function normalizeAddress(address) {
  if (!address) {
    return undefined;
  }
  const { province, state, ...otherFields } = address;
  return {
    ...otherFields,
    province: !isAmericanAddress(address) ? province : '',
    state: isAmericanAddress(address) ? state : '',
  };
}

export function getCountryName(address) {
  // countryCode may be the full country name for old users.
  const { country: countryCode } = address || {};
  return countryNames[countryCode] || countryCode;
}

export function formatAddress(address) {
  if (!address) {
    return '';
  }

  const addressParts = [
    address.street1 || address.street, // TODO delete street1 when all addresses will be of type IAddressNew
    address.city,
    address.state || address.province,
    address.postalCode,
    getCountryName(address),
  ].filter(Boolean);

  return addressParts.join(', ');
}

/*
 * Gets value of a deeply nested object property.
 * @param obj Object
 * @param key String in a format 'key1.key2.key3'
 */
export function getObjectValue(obj, key) {
  const props = key.split('.');
  const value = props.reduce((o, k) => o[k] || '', obj);
  return value || undefined;
}

/*
 * Recursively set new value of a nested object and return it.
 * @param obj Object an object which should be updated
 * @param key String a filed of the objec, each dot signifies a new nesting level.
 * @param value Mixed the new value of the field.
 * @return Object a new object with one field updated.
 */
export function getUpdatedObj(obj, key, value) {
  const [first, ...rest] = key.split('.');

  const newValue = rest.length > 0 ? getUpdatedObj(obj[first] || {}, rest.join('.'), value) : value;

  if (Array.isArray(obj)) {
    const newArray = [...obj];
    newArray.splice(+first, 1, newValue);
    return newArray;
  }

  return {
    ...obj,
    [first]: newValue,
  };
}

// API used to store some urls without protocol, e.g. www.subdomain.bloom.io,
// use this func to make sure that those are valid url addresses.
export function ensureValidUrl(url) {
  return url.replace(/^(https?:)?(\/\/)?/, 'https://');
}

export const isYoutubeLink = (url = '') => {
  //https://stackoverflow.com/a/19377429
  const regex = /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/;
  return regex.test(url.trim());
};

export const isVimeoLink = (url = '') => {
  const regex = /^https?:\/\/(player\.)?vimeo\.com\//;
  return regex.test(url.trim());
};

export const isLocalVideoLink = (url = '') => {
  const regex = /\.mp4|\.ogv|\.webm/;
  return regex.test(url.trim());
};

export const getProviderByLink = ({ videoSource = '' }) => {
  if (isYoutubeLink(videoSource)) {
    return 'youtube';
  }

  if (isVimeoLink(videoSource)) {
    return 'vimeo';
  }

  if (isLocalVideoLink(videoSource)) {
    return 'local';
  }

  return '';
};

export function round(num: number, decimals = 2): number {
  // https://stackoverflow.com/a/18358056/22680353
  // https://app.clickup.com/t/868c7uk75
  const n = Math.round(Number(`${num}e${decimals}`));
  return Number(`${n}e-${decimals}`);
}

// source: https://stackoverflow.com/a/18650828/4725218
const sizes = ['', 'K', 'M', 'B'];
export function humanizeNumber(count, decimals = 2) {
  if (count === 0) {
    return '0';
  }

  const k = 1000;
  const dm = Math.max(0, decimals);

  const i = Math.max(0, Math.floor(Math.log(count) / Math.log(k)));

  return `${parseFloat((count / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function parseJwt(token = '') {
  const base64Url = token.split('.')[1] || '';
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = '';

  try {
    jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
  } catch (e) {}

  let payload = null;
  try {
    payload = JSON.parse(jsonPayload);
  } catch (e) {}

  return payload;
}

export function getPercentageDiff(amount1: number, amount2: number): number {
  const difference = (amount2 - amount1) / amount1;
  const percentage = Math.round(Math.abs(difference + Number.EPSILON) * 100);

  return percentage * Math.sign(difference);
}

export function roundDownToFactor(number: number, factor: number): number {
  if (factor === 0) {
    return number;
  }
  return Math.floor(number / factor) * factor;
}

export function roundUpToFactor(number: number, factor: number): number {
  if (factor === 0) {
    return number;
  }
  return Math.ceil(number / factor) * factor;
}

export const preventDefault = (e: React.UIEvent<HTMLElement>) => {
  e.preventDefault();
};

export function isUrl(url: string) {
  return /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(url);
}

import React from 'react';

import type { IIconProps } from './types';

const CloseIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  rotate = 0,
  width = 20,
  ...restProps
}) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width} {...restProps}>
    <path
      clipRule="evenodd"
      d="M4.41 4.41a.833.833 0 011.18 0L10 8.822l4.41-4.41a.833.833 0 111.18 1.178L11.178 10l4.41 4.41a.833.833 0 11-1.178 1.18L10 11.177 5.588 15.59a.833.833 0 01-1.178-1.178L8.82 10l-4.41-4.41a.833.833 0 010-1.18z"
      fill={color}
      fillRule="evenodd"
      transform={`rotate(${rotate} 10 10)`}
    />
  </svg>
);

export { CloseIcon };

import { DEFAULT_ERROR_MESSAGE } from '@bloom/library/components/FlashMessageV2/constants';
import { IErrorAsyncAction } from '@bloom/library/components/hooks/useFetch';

export function getErrorMessageFromResponse(response: IErrorAsyncAction): string {
  const { clientMessages = [], message = DEFAULT_ERROR_MESSAGE } = response.error;
  if (clientMessages.length === 0) {
    return message;
  }

  let clientMessage = '';
  const { messages = [] } = clientMessages[0] || {};

  if (typeof messages[0] === 'string') {
    clientMessage = messages[0];
  }

  if (typeof messages[0] === 'object') {
    const nestedMessages = Object.values(messages[0])[0] || [];
    clientMessage = nestedMessages[0] || '';
  }

  return clientMessage || message;
}

import React, { useEffect } from 'react';

import { TransitionOptions, useTransitionState } from 'react-transition-state';
import { twMerge } from 'tailwind-merge';

interface Props extends TransitionOptions {
  active: boolean;
  children: React.ReactNode;
  className?: string;
}

const CSSTransition: React.FC<Props> = (props) => {
  const { active, children, className, ...transitionOptions } = props;

  const [{ isMounted, status: transitionStatus }, toggle] = useTransitionState({
    initialEntered: active,
    mountOnEnter: true,
    preEnter: true,
    timeout: 300,
    unmountOnExit: true,
    ...transitionOptions,
  });

  useEffect(() => {
    toggle(active);
  }, [active, toggle]);

  if (isMounted) {
    return React.isValidElement(children)
      ? React.cloneElement(children, {
          ...children.props,
          className: twMerge(className, children.props.className, transitionStatus),
        })
      : null;
  }

  return null;
};

export { CSSTransition };

'use client';
import React, { useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';

import { CSSTransition } from '@bloom/ui/components/CSSTransition';
import { CloseIcon } from '@bloom/ui/components/Icons/Close';

import Button from '@bloom/library/components/Button/Button';
import { FlashMessageTypes } from '@bloom/library/types/flashMessage';
import { escapeHTML } from '@bloom/library/utils/string';

import style from './FlashMessageV2.module.css';

interface IMessageProps {
  children?: React.ReactNode;
  className?: string;
  'data-testid'?: string;
  onClick?: () => void;
  onClose?: () => void;
  variant: FlashMessageTypes;
}

interface IAnimatedMessageProps extends IMessageProps {
  wrapperClassName?: string;
}

interface IFlashMessageV2Props extends IAnimatedMessageProps {
  documentObj?: Document;
  parentAsRoot?: boolean;
  zIndex?: number;
}

export const Message: React.FC<IMessageProps> = ({
  children: message,
  className,
  'data-testid': dataTestId = 'flash-message',
  onClose,
  variant,
}) => {
  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };
  return (
    <div className={twMerge(style.message, style[variant], className)} data-testid={dataTestId}>
      <div
        className={style.content}
        dangerouslySetInnerHTML={
          typeof message === 'string' ? { __html: escapeHTML(message) } : undefined
        }
      >
        {typeof message !== 'string' ? message : undefined}
      </div>
      <Button
        className={style.closeButton}
        data-testid={`${dataTestId}-close-button`}
        onClick={handleClose}
      >
        <CloseIcon />
      </Button>
    </div>
  );
};

export const AnimatedMessage: React.FC<IAnimatedMessageProps> = ({
  children,
  className,
  onClick,
  onClose,
  variant = FlashMessageTypes.ERROR,
  wrapperClassName,
}) => {
  const [message, setMessage] = useState(children);
  const [type, setTime] = useState<FlashMessageTypes>(variant);
  const [isShown, setIsShown] = useState(!!children);

  // Id of the timeout that resets the message after animation finishes.
  const timeoutId = useRef<number>(0);

  useEffect(() => {
    // Reset the message after animation finishes.
    if (!children && message) {
      setIsShown(false);
      timeoutId.current = window.setTimeout(() => {
        setMessage('');
        setTime(FlashMessageTypes.UNSET);
      }, 350);
    }
    // Update the message.
    if (children && children !== message) {
      setIsShown(true);
      setMessage(children);
      setTime(variant);
      // In case there's a timeout, clear it.
      // We don't want to reset the message anymore.
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = 0;
      }
    }
    // Clear timeout on unmount.
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [children, message, variant]);

  return (
    <CSSTransition active={isShown}>
      <div
        className={twMerge('fixed left-6 right-6 top-6', style.container, wrapperClassName)}
        onClick={onClick}
        role="button"
        style={{ zIndex: 10001 }}
      >
        <Message className={className} onClose={onClose} variant={type}>
          {message}
        </Message>
      </div>
    </CSSTransition>
  );
};

export const FlashMessageV2: React.FC<IFlashMessageV2Props> = ({
  children,
  className,
  // A hatch to escape an iframe document.
  documentObj,
  onClick,
  onClose,
  parentAsRoot = false,
  variant,
  wrapperClassName,
  zIndex = 20000,
}) => {
  const divElement = useRef<HTMLDivElement>();

  useEffect(() => {
    const doc = documentObj || document;
    divElement.current = doc.createElement('div');
    divElement.current.style.cssText = `position:absolute;z-index:${zIndex};`;
    doc.body.appendChild(divElement.current);

    return () => {
      if (divElement.current) {
        if (doc.body.contains(divElement.current)) {
          doc.body.removeChild(divElement.current);
        }
      }
    };
    // eslint-disable-next-line
  }, []);

  if (parentAsRoot) {
    return (
      <AnimatedMessage
        className={className}
        onClick={onClick}
        onClose={onClose}
        variant={variant}
        wrapperClassName={wrapperClassName}
      >
        {children}
      </AnimatedMessage>
    );
  }

  if (!divElement.current) {
    return null;
  }

  return createPortal(
    <AnimatedMessage
      className={className}
      onClose={onClose}
      variant={variant}
      wrapperClassName={wrapperClassName}
    >
      {children}
    </AnimatedMessage>,
    divElement.current
  );
};

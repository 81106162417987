import React from 'react';

import { FlashMessageV2 } from '@bloom/library/components/FlashMessageV2/FlashMessageV2';

import { useInfoMessage } from './info-message-context';

const FlashMessage: React.FC<Partial<React.ComponentProps<typeof FlashMessageV2>>> = (props) => {
  const { hideMessage, message, variant } = useInfoMessage();

  return (
    <FlashMessageV2 {...props} onClose={hideMessage} variant={variant}>
      {message}
    </FlashMessageV2>
  );
};
export { FlashMessage };

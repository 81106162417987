import React from 'react';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  'data-testid': string;
  isDisabled?: boolean;
  styles?: React.CSSProperties;
}

const Button = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<IProps>>(
  (props, forwardRef) => {
    const { children, isDisabled, onClick, styles, type = 'button', ...restProps } = props;

    function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
      if (typeof onClick === 'function' && !isDisabled) {
        onClick(e);
      }
    }

    return (
      <button {...restProps} onClick={handleClick} ref={forwardRef} style={styles} type={type}>
        {children}
      </button>
    );
  }
);

export default Button;
